<template>
  <div>
    <div id="Content" >
      <div>
        <div class="background-1 row" style="margin-left: 0px!important;">
          <div class="col-lg-8 col-md-6 col-sm-12 d-flex justify-content-center align-self-start h-100">
            <div class="d-flex" title="Homepage del software DIRE" alt="Homepage del software delle Camere di Commercio DIRE, 
            realizzato da InfoCamere, per l'invio pratiche al Registro Imprese"
              tag="Homepage DIRE, Software DIRE, Software DIRE InfoCamere, DIRE InfoCamere, DIRE Registro Imprese">
              <div class="p-title d-md-block d-none margin-title">
              <div>
                <div class="text-1 bold">{{ $t("msg.title1") }}</div>
                <div class="text-1">{{ $t("msg.title2") }}</div>
              </div>
              </div>

              <div class="p-title d-block d-md-none text-center">
                <div class="text-1 bold" v-html="$t('msg.title3')"></div>
                <div class="text-1">{{ $t("msg.title4") }}</div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12 d-flex justify-content-center align-self-center pr-5"
            style="padding-top: 2rem">
            <img class="img-1" src="@/assets/images/home/DIRE-mockup-portatile.png" />
          </div>
        </div>

        <div>
          <div class="row" id="cos-e-link">
            <div class="div-text-2 col-12" id="cos-e">
            </div>
          </div>
          <div class="container">
            <div class="banner-block row" id="p-smartphone">
              <div class="d-flex text-center justify-content-center align-items-center col-lg-5 col-md-5 col-xl-5  d-none d-md-block video-responsive" v-if="isDesk" id="desk-banner">
                <a href="https://id.infocamere.it/firma-digitale-camera-di-commercio.html" target="_blank"><img id="video_01_item" class="d-flex img-responsive vimeoIframe" src="@/assets/images/home/banner-dire-new.jpg" /></a>
                <!--
                <iframe  id="video_01_item" class="embed-responsive-item vimeoIframe"
                  frameborder="0" v-bind:src=$data.url_video_banner allowfullscreen="allowfullscreen"
                  mozallowfullscreen="mozallowfullscreen" msallowfullscreen="msallowfullscreen"
                  oallowfullscreen="oallowfullscreen" webkitallowfullscreen="webkitallowfullscreen"></iframe>-->
              </div>
              <div class="col-lg-7 col-md-7 col-sm-12 col-xl-7">
                <span id="p-text-3" class="text-4 bold">{{ $t("msg.section1") }}</span>
                <p v-html="$t('msg.section1-desc')" class="text-3" id="p-text-3"></p>
              </div>
              <div class="d-flex text-center justify-content-center col-sm-12 d-block d-md-none my-auto">
                <a href="https://id.infocamere.it/firma-digitale-camera-di-commercio.html?utm_source=dire-home-banner&utm_medium=referral&utm_campaign=titolare-effettivo" target="_blank"><img id="video_01_item"  class="d-flex img-responsive vimeoIframe" src="@/assets/images/home/banner-dire.jpg" /></a>
                <!--
                <iframe id="video_01_item" class="embed-responsive-item vimeoIframe" frameborder="0"
                  v-bind:src=$data.url_video_banner allowfullscreen="allowfullscreen"
                  mozallowfullscreen="mozallowfullscreen" msallowfullscreen="msallowfullscreen"
                  oallowfullscreen="oallowfullscreen" webkitallowfullscreen="webkitallowfullscreen"></iframe>-->
              </div>
            </div>
          </div>
        </div>

        <div class="container">
          <div class="row h-100">
            <div class="col-md-6 col-12 my-auto" title="Assistenza normativa integrata nel software DIRE" alt="Assistenza normativa integrata nel software delle Camere di Commercio DIRE, 
        realizzato da InfoCamere, per l'invio pratiche al Registro Imprese"
              tag="Assistenza normativa DIRE, Software DIRE invio pratiche, Software DIRE InfoCamere, DIRE InfoCamere, DIRE Registro Imprese Assistenza"
              id="p-smartphone">
              <span class="text-4">{{ $t("msg.section1-2-title") }}</span>
              <p v-html="$t('msg.section1-2-desc')" class="text-3"></p>
            </div>
            <div class="col-md-6 col-12 text-center my-auto">
              <img class="img-2" src="@/assets/images/home/DIRE-mockup-ipad2.png" />
            </div>
          </div>
        </div>
        <div class="block-3 mt-5 p-4">
          <div class="row h-100">
            <div class="col-lg-2 col-12 d-none d-lg-block">
              <div class="row h-100">
                <div class="col-12 my-auto d-flex align-items-center justify-content-center"
                  style="height:70% !important;" id="cosa-occorre-lg">
                  <div class="block-3-1 my-auto ">
                    <b>{{ $t("msg.cosa-occorre")}}</b>
                  </div>
                </div>
              </div>
            </div>
            <div class="text-4 col-12 d-lg-none d-block">
              <b>{{ $t("msg.strumenti-pratica") }}</b>
              <div class="mt-3 block-hr"></div>
            </div>

            <div class="p-strumenti-pratica col-lg-3 col-md-4 col-12">
              <span class="text-5 bold" v-html="$t('msg.firma-digitale-title')">
              
            </span>
              <p class="text-3"> 
                <span v-html="$t('msg.firma-digitale-desc')"></span>
                <a class="links"  target="_blank" v-bind:href="$data.url_prestatori_servizi_digitali"> 
                  <span v-html="$t('msg.prestatori-serv-digit')"></span>
                </a>
                <span v-html="$t('msg.firma-digitale-desc2')"></span>
              </p>
            </div>
            <div class="p-strumenti-pratica col-lg-3 col-md-4 col-12">
              <span class="text-5 bold" v-html="$t('msg.pec-title')"></span>
              <p class="text-3"><span v-html="$t('msg.pec-desc')"></span><a class="links"  target="_blank" v-bind:href="$data.url_pubblici_gestori"><span v-html="$t('msg.pubblici-gestori')"></span></a>
               <span v-html="$t('msg.pec-desc2')"></span>
              </p>
            </div>
            <div class="p-strumenti-pratica col-lg-3 col-md-4 col-12">
              <span class="text-5 bold">{{ $t("msg.conto-title") }}</span>
              <div class="d-block d-md-none" id="cosa-fare-link"></div>
              <p class="text-3" v-html="$t('msg.conto-desc')"></p>
              <div class="d-none d-md-block .d-lg-none" id="cosa-fare-link3"></div>
            </div>
          </div>
          <div class="d-none d-lg-block" id="cosa-fare-link2"></div>
        </div>

          <div class="d-flex justify-content-center p-3" style="margin-top: 40px; margin-bottom: 4rem" id="cosa-fare">
            <span v-html="$t('msg.section2')" class="text-2 bold"></span>
          </div>
        <div class="container">
          <div class="container-5">
            <div class="row">
              <div class="col-12 col-md-3">
                <div class="text-center">
                  <img class="img-number1" src="@/assets/images/home/DIRE-1.png" />
                  <div class="numbers-hr-separator d-none d-md-block"></div>
                </div>
                <div class="text-center"><a class="links" target="_blank" v-bind:href="$data.url_reg_imprese"> <div class="number-title text-3 op-70" v-html="$t('msg.registrati-title')"></div></a></div>
                <div class="number-text text-3 sm-left">
                <span v-html="$t('msg.registrati-desc2')"></span>
                <a class="links"  target="_blank" v-bind:href="$data.url_reg_imprese"> 
                  <span v-html="$t('msg.reg-imprese')"> </span>
                </a><span v-html="$t('msg.registrati-desc3')"></span>
                </div>
                <div class="numbers-separator d-block d-md-none mx-auto"></div>
              </div>
              <div class="col-12 col-md-3">
                <div class="text-center">
                  <img class="img-numbers" src="@/assets/images/home/DIRE-2.png" />
                  <div class="numbers-hr-separator d-none d-md-block"></div>
                </div>
                  <div class="text-center"><div class="number-title text-3 op-80" v-html="$t('msg.carica-conto-title')"></div></div>
                <div class="number-text text-3 sm-left">
                    {{ $t("msg.carica-conto-desc2") }}
                    <a class="links"  target="_blank" v-bind:href="$data.url_iconto"> {{ $t("msg.carica-conto-desc3") }}</a>
                    {{ $t("msg.carica-conto-desc4") }}
                </div>
                <div class="numbers-separator d-block d-md-none mx-auto"></div>
              </div>
              <div class="col-12 col-md-3">
                <div class="text-center">
                  <img class="img-numbers" src="@/assets/images/home/DIRE-3.png" />
                  <div class="numbers-hr-separator d-none d-md-block"></div>
                </div>
                <div class="text-center"><div class="number-title text-3 op-95" v-html="$t('msg.compila-spedisci-title')"></div></div>
                <div class="number-text text-3 sm-left" v-html="$t('msg.compila-spedisci-desc')"></div>
                <div class="numbers-separator d-block d-md-none mx-auto"></div>
              </div>
              <div class="col-12 col-md-3">
                <div class="text-center">
                  <img class="img-numbers" src="@/assets/images/home/DIRE-4.png" />
                </div>
                <div class="text-center"><div class="number-title text-3" v-html="$t('msg.verifica-title')"></div></div>
                <div class="number-text text-3 sm-left" v-html="$t('msg.verifica-desc')"></div>
              </div>
            </div>
          </div>

          <div class="row mt-5">
            <div class="col-12 d-flex justify-content-center">
              <a v-bind:href="$data.url_registrati_reg_imprese" target="_blank"><button class="btn-blue">
                  {{ $t("msg.registrati-telemaco") }}
                </button></a>
            </div>
          </div>
        </div>

  <!--
        <div class="mb-sm container-fluid d-lg-none d-block mt-5">
          <div class="div-smartphone row mt-5 h-100">
            <div class="col-md-4 col-sm-0 d-none d-md-block">
              <div class="d-flex justify-content-center">
                <img class="img-3 d-none d-md-block" src="@/assets/images/home/DIRE-mockup-iphone.png" />
              </div>
            </div>
            <div class="col-md-7 col-sm" title="Link a Telemaco nel software DIRE" alt=" Link a Telemaco nel software DIRE per conferma di ricezione 
                della pratica"
              tag="Conferma ricezione pratica DIRE, Diario messaggi DIRE, Software DIRE invio pratiche, Software DIRE InfoCamere, DIRE InfoCamere, DIRE Registro Imprese Assistenza">
              <div class="d-flex align-items-start">
                <div class="sm-center md-justify p-2">
                  <span class="text-4">{{
                  $t("msg.come-verificare-pratica-title")
                }}</span>
                  <p class="text-3" v-html="$t('msg.come-verificare-pratica-desc1')"></p>
                  <p class="text-3" v-html="$t('msg.come-verificare-pratica-desc2')"></p>
                </div>
              </div>
            </div>
            <div class="col-sm-12 d-md-none d-sm-block">
              <div class="d-flex justify-content-center">
                <img class="img-3 " src="@/assets/images/home/DIRE-mockup-iphone.png" />
              </div>
              <div class="d-block d-lg-none" id="assistenza2"></div>
            </div>
          </div>
        </div> -->
        
        <div class="d-block d-lg-none" id="assistenza2"></div>
      </div>
<!--
      <div class="container d-lg-block d-none mt-5 mb-5">
        <div class="div-smartphone row mt-5 h">
          <div class="d-flex col-4 text-center">
            <div>
              <img class="img-3" src="@/assets/images/home/DIRE-mockup-iphone.png" />
            </div>
          </div>
          <div class="d-flex col-7 justify-content-center mt-5"
            title="Link a Telemaco nel software DIRE" alt=" Link a Telemaco nel software DIRE per conferma di ricezione 
                della pratica"
            tag="Conferma ricezione pratica DIRE, Diario messaggi DIRE, Software DIRE invio pratiche, Software DIRE InfoCamere, DIRE InfoCamere, DIRE Registro Imprese Assistenza">
            <div>
              <span class="text-4">{{
              $t("msg.come-verificare-pratica-title")
            }}</span>
              <p class="text-3"  v-html="$t('msg.come-verificare-pratica-desc1')"></p> 
              <p class="text-3" v-html="$t('msg.come-verificare-pratica-desc2')"></p>
              <div  id="assistenza"></div>
            </div>
          </div>
        </div>
      </div>
-->
      <div  id="assistenza"></div>
      <div class="row margin-assist-md-lg">
        <div class="col-12 text-center">
          <div class="text-2 bold">{{ $t("msg.section3") }}</div>
        </div>
      </div>

      <div class="container">
        <div id="accordionDiv1" class="collapse-div" role="tabpanel" style="margin-top:50px">
          <div class="collapse-header" id="headingA1">
            <button data-toggle="collapse" data-target="#accordion1" aria-expanded="false" aria-controls="accordion1"
              style="color: #008fbf;" class="text-3">
              {{ $t("msg.accordion-title-1") }}
            </button>
          </div>
          <div id="accordion1" class="collapse" role="tabpanel" aria-labelledby="headingA1"
            data-parent="#accordionDiv1">
            <div class="collapse-body text-3">
              {{ $t("msg.accordion-text-1") }}
            </div>
          </div>
          <div class="accordion-hr"></div>
          <div class="collapse-header" id="headingA2">
            <button data-toggle="collapse" data-target="#accordion2" aria-expanded="false" aria-controls="accordion2"
              style="color: #008fbf;" class="text-3">
              {{ $t("msg.accordion-title-2") }}
            </button>
          </div>
          <div id="accordion2" class="collapse" role="tabpanel" aria-labelledby="headingA2"
            data-parent="#accordionDiv1">
            <div class="collapse-body text-3" v-html="$t('msg.accordion-text-2')">
            </div>
          </div>
        </div>
        <div class="accordion-hr"></div>
        <div class="collapse-header" id="headingA3">
          <button data-toggle="collapse" data-target="#accordion3" aria-expanded="false" aria-controls="accordion3"
            style="color: #008fbf;" class="text-3">
            {{ $t("msg.accordion-title-3") }}
          </button>
        </div>
        <div id="accordion3" class="collapse" role="tabpanel" aria-labelledby="headingA3" data-parent="#accordionDiv1">
          <div class="collapse-body text-3">
            {{ $t("msg.accordion-text-3") }} <br>
            {{ $t("msg.accordion-text-3-1") }} <a class="links"  target="_blank" v-bind:href="$data.url_listino"> {{ $t("msg.accordion-text-3-listino") }}</a>
            {{ $t("msg.accordion-text-3-2") }} <a class="links"  target="_blank" v-bind:href="$data.url_iconto"> {{ $t("msg.carica-conto-desc3") }}</a>
            {{ $t("msg.accordion-text-3-3") }} 

          </div>
        </div>
        <div class="accordion-hr"></div>
        <div class="collapse-header" id="headingA4">
          <button data-toggle="collapse" data-target="#accordion4" aria-expanded="false" aria-controls="accordion4"
            style="color: #008fbf;" class="text-3">
            {{ $t("msg.accordion-title-4") }}
          </button>
        </div>
        <div id="accordion4" class="collapse" role="tabpanel" aria-labelledby="headingA4" data-parent="#accordionDiv1">
          <div class="collapse-body text-3">
            {{ $t("msg.accordion-text-4-1") }} <a class="links"  target="_blank" v-bind:href="$data.url_iconto"> {{ $t("msg.carica-conto-desc3") }}</a> .<br>
            {{ $t("msg.accordion-text-4-1-1") }} <b>
            {{ $t("msg.accordion-text-4-1-2") }}</b> 
            {{ $t("msg.accordion-text-4-1-3") }} <b> 
            {{ $t("msg.accordion-text-4-1-4") }}</b> {{ $t("msg.accordion-text-4-1-5") }}

          </div>
        </div>
        <div class="accordion-hr"></div>
      </div>

      <div class="row mt-5 mb-5">
        <div class="col-12 d-flex justify-content-center">
          <a v-bind:href="$data.url_assistenza_reg_imprese" target="_blank"><button
              class="btn-blue">{{ $t("msg.aiuto") }}</button></a>
        </div>
      </div>
    </div>
  <!--
    <div class="cookiebar" id="cookiebar" style="display: none;">
      <div class="d-flex flex-wrap">
        <div class="col-xs-12 col-sm-9">
          <p style="width:100% !important">{{ $t("msg.cookiebar-text-1") }} </p>
        </div>
        <div class="d-flex flex-wrap cookiebar-buttons justify-content-end align-items-center col-xs-12 col-sm-3 ">
			<a href="https://informative.infocamere.it/depositi-e-istanze-registro-imprese" class="cookiebar-btn p-3" >{{ $t("msg.cookiebar-informativa")}}<span class="sr-only"> {{ $t("msg.cookiebar-name")}}</span></a>
				<button v-on:click="acceptCookies" data-accept="cookiebar" class="cookiebar-btn cookiebar-confirm p-3" id="cookie-accept" style="margin-left: 0px !important;">
					{{ $t("msg.cookiebar-accetto")}}<span class="sr-only"> {{ $t("msg.cookiebar-cookies")}}</span>
				</button>
				<button v-on:click="declineCookies" class="cookiebar-btn cookiebar-confirm p-3 " style="margin-left: 0px;" id="cookie-dismiss">
					{{ $t("msg.cookiebar-non-accetto")}}<span class="sr-only"> {{ $t("msg.cookiebar-cookies")}}</span>
				</button>
		</div>
      </div>
    </div>-->
  </div>
</template>

<script>
  export default {
    name: "ContentComponent",
    props: {},
    data(){
      return{
        url_video_banner : process.env.VUE_APP_I18N_URL_VIDEO_BANNER,
        url_prestatori_servizi_digitali : process.env.VUE_APP_I18N_URL_PRESTATORI_SERVIZI_DIGITALI,
        url_pubblici_gestori : process.env.VUE_APP_I18N_URL_PUBBLICI_GESTORI,
        url_reg_imprese : process.env.VUE_APP_I18N_URL_REGISTRO_IMPRESE,
        url_iconto : process.env.VUE_APP_I18N_URL_ICONTO,
        url_registrati_reg_imprese : process.env.VUE_APP_I18N_URL_REGISTRATI_REGISTRO_IMPRESE,
        url_assistenza_reg_imprese : process.env.VUE_APP_I18N_URL_ASSISTENZA_REGISTRO_IMPRESE,
        url_listino : process.env.VUE_APP_I18N_URL_LISTINO,
        isDesk: (document.getElementById("app").offsetWidth>=758)
      };
    },
    created(){
     window.addEventListener('resize', this.setIsDesk);
    },
    mounted() {
      // check esistenza cookies
      if (!this.checkCookie()) {
        setTimeout(() => {
          if (document.getElementById('cookiebar')) {
            document.getElementById('cookiebar').style.display = 'block!important';
            document.getElementById('cookiebar').classList.add('show');
          }
        }, 400);
      }
    },
    methods: {
      setIsDesk: function(){
        this.isDesk=(document.getElementById("app").offsetWidth>=758);
      },
      setCookie: function (cname, cvalue, exdays) {
        var d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        var expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
      },
      getCookie: function (cname) {
        var name = cname + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for (var i = 0; i < ca.length; i++) {
          var c = ca[i];
          while (c.charAt(0) == ' ') {
            c = c.substring(1);
          }
          if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
          }
        }
        return "";
      },
      checkCookie: function () {
        var username = this.getCookie("cookies_consent");
        if (username) {
          return true;
        } else {
          return false;
        }
      },
      // accetta cookie su click su "Accetta" della barra
      acceptCookies: function () {
        var elem = document.getElementById('cookiebar');
        if(!this.checkCookie() && (elem.style.display!= 'none' || elem.classList.contains('show'))){
          this.setCookie('cookies_consent', true, 30)
          console.log('cookie accettati');
        }
        if (elem) {
          elem.style.display = 'none!important';
          elem.classList.remove('show');
        }
        //enable GTM
        this.setCookie('optOutGoogleTracking', false);
      },

      declineCookies: function () {
        var elem = document.getElementById('cookiebar');
        if(!this.checkCookie() && (elem.style.display!= 'none' || elem.classList.contains('show'))){
          this.setCookie('cookies_consent', false, 30);
          console.log('cookie rifiutati');
        }
        if (elem) {
          elem.style.display = 'none!important';
          elem.classList.remove('show');
        }
        //disable GTM
        this.setCookie('_ga', undefined);
        this.setCookie('optOutGoogleTracking', true);
      },

    },
  };
</script>

<style lang="scss">
  @import "@/assets/sass/dire.scss";
</style>